<template>
  <div v-if="isAdminConnected">
    <v-navigation-drawer
      app
      dark
      color="primary"
      :expand-on-hover="isMobile"
      permanent
      class="elevation-4"
    >
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="title">
            {{ appName }}
          </v-list-item-title>
          <v-list-item-subtitle>
            Administrateur
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list>
        <v-list-item
          v-for="(item, i) in menuItems"
          :key="i"
          link
          :to="{ path: item.route }"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.label }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <template v-slot:append>
        <v-divider></v-divider>
        <v-list-item link :to="{ path: '/logout' }">
          <v-list-item-icon>
            <v-icon>mdi-logout</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            Déconnexion
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { isMobile } from 'mobile-device-detect'

export default {
  name: 'SideNavBar',

  data: () => ({
    appName: process.env.VUE_APP_TITLE || 'OFFICINES',
    isMobile,
    drawer: false,
    mini: false
  }),

  computed: {
    menuItems() {
      let menuItemsArray = [
        {
          route: '/',
          label: 'Retour au site',
          icon: 'mdi-home'
        }
      ]

      if (this.isAdminConnected) {
        menuItemsArray.push(
          {
            route: '/admin/dashboard',
            label: 'Tableau de bord',
            icon: 'mdi-view-dashboard'
          },
          {
            route: '/admin/historique',
            label: 'Historique',
            icon: 'mdi-form-select'
          },
          {
            route: '/admin/users',
            label: 'Utilisateurs',
            icon: 'mdi-view-list'
          }
        )
      }

      return menuItemsArray
    },

    currentUser() {
      return this.$store.state.auth.user
    },

    isUserConnected() {
      if (this.currentUser && this.currentUser.role) {
        return this.currentUser.role.includes('Utilisateur')
      }
      return false
    },

    isAdminConnected() {
      if (this.currentUser && this.currentUser.role) {
        return this.currentUser.role.includes('Administrateur')
      }
      return false
    }
  },

  methods: {
    /*
    logOut() {

      this.$store
        .dispatch('auth/logout')
        .then(response => {
          this.$toasted.show(response.data.message, {
            type: 'success',
            duration: '5000',
            position: 'top-center'
          })
          this.$router.push('/login')
        })
        .catch(err => {
          this.$toasted.show(err.error, {
            type: 'error',
            position: 'top-center',
            action: {
              text: 'Cancel',
              duration: '5000',
              onClick: (e, toastObject) => {
                toastObject.goAway(0)
              }
            }
          })
        })
    }*/
  },

  components: {
    btn: () => import('./BasicBtn.vue'),
    'user-menu': () => import('./UserMenu.vue')
  }
}
</script>

<style scoped>
.v-tab {
  /*text-transform: none !important;*/
}
.custom-active {
  /*position: relative;*/
  overflow: visible;
}
.custom-active::after {
  content: ' ';
  position: absolute;
  z-index: 500;
  top: 54px;
  height: 3px;
  width: 100%;
  visibility: visible;
  background-color: var(--v-primary-base);
}
</style>
